import { Controller } from 'stimulus';
// import { map, template, each } from 'underscore';
export default class extends Controller {
  static targets = ["hiddenForm", "hiddenField"];

  connect() {
  }

  pay() {
    this.hiddenFieldTarget.value = window.sessionStorage.cart;
    // delete window.sessionStorage.cart;  => clear session storage before submit form => but i hide this line so that there wont be error when go back from stripe check out and check out again. not sure whats the other implications but it work for now
    this.hiddenFormTarget.submit();
  }
}
