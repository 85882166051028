import { Controller } from 'stimulus';
import { map, template, each } from 'underscore';
export default class extends Controller {
    static targets = ["menuTab", "menuList", "optionsTpl", "modal", "announcement",
        "viewOrderBtn", "hiddenForm", "hiddenField", "optionsBtn", "qtyAdjuster"];

    connect() {
        this.announcementTpl = template(`<p id="delivery-date">
            Delivery Date: {{deliveryDate}}
        </p>
        <p>
            Order closes: {{orderCloses}}
            <br>
            Delivery Fee: {{deliveryFee}}
        </p>`, {
            interpolate: /\{\{(.+?)\}\}/g,
            evaluate: /\{%(.+?)%\}/g,
        })
        // convert multiple-dimensions array to multiple-layers dictionary
        this.listsMap = Object.assign({}, ...menuLists.map((list) => {
            let itemsMap = Object.assign({}, ...list.items.map((item) => {
                return { [item.id]: item };
            }));
            let clonedList = { ...list };
            clonedList.items = itemsMap;
            return { [list.menuListId]: clonedList };
        }));
        this.activeListID = menuLists[0].menuListId;
        this.cart = {}; // structure => {itemID: {:qty, options: {values: [{:name, :value}], :quantity}}}
        if (window.sessionStorage.cart) {
            this.cart = JSON.parse(window.sessionStorage.cart);
            this.restoreStatus();
        }
    }

    restoreStatus() {
        this.updateViewOrderBtn();
        const cart = this.cart;
        const controller = this;
        each(this.optionsBtnTargets, btn => {
            const itemID = btn.dataset.menuItemId;
            if (cart[itemID] && cart[itemID].quantity > 0) {
                const qtyElement = btn.nextElementSibling;
                qtyElement.innerHTML = `${cart[itemID].quantity}`;
                if (cart[itemID].quantity > 0) {
                    qtyElement.style.display = "block";
                } else {
                    qtyElement.style.display = "none";
                }
            }
        });
        each(this.qtyAdjusterTargets, adjuster => {
            const itemID = adjuster.dataset.menuItemId;
            if (cart[itemID] && cart[itemID].quantity > 0) {
                adjuster.dataset.initialQty = cart[itemID].quantity;
            }
        });
    }

    switchMenuList(event) {
        let menuTabs = this.menuTabTargets;
        menuTabs.forEach(element => {
            element.classList.remove("active");
        });
        let targetElement = event.currentTarget;
        targetElement.classList.add("active");

        let targetMenuListID = targetElement.dataset.targetListId;
        console.log(targetElement.dataset.targetListId)
        let menuLists = this.menuListTargets;
        menuLists.forEach(element => {
            if (element.dataset.menuListId == targetMenuListID) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
        this.activeListID = targetMenuListID;
        this.refreshAnnouncement();
    }

    refreshAnnouncement() {
        const list = this.listsMap[this.activeListID];
        this.announcementTarget.innerHTML = this.announcementTpl(
            {
                deliveryDate: list.deliveryDate,
                orderCloses: list.orderCloses,
                deliveryFee: list.deliveryFee,
            }
        );
    }

    prepareOptions(event) {
        const item = this.listsMap[this.activeListID].items[event.currentTarget.dataset.menuItemId];
        item.picture = event.currentTarget.dataset.picture; // read real picture from the element's data-picture property
        this.dispatch("optionsReady", {
            detail: {
                item: item,
                cartItem: this.cart[item.id],
                source: event.currentTarget,
            },
            target: event.currentTarget
        });
    }

    itemQtyChanged(event) {
        if (event.detail.quantity > 0) {
            this.cart[event.detail.itemID] = { quantity: event.detail.quantity, menuListId: this.activeListID };
        } else {
            delete this.cart[event.detail.itemID];
        }
        this.updateViewOrderBtn();
        console.log("new cart: ", this.cart);
    }

    optionsSelected(event) {
        const itemID = event.currentTarget.dataset.menuItemId;
        let subQty = 0;
        if (event.detail.selected.length > 0) {
            subQty = map(event.detail.selected, s => {
                return s.quantity;
            }).reduce((a, b) => a + b, 0); // sum array
            this.cart[itemID] = { options: event.detail.selected, quantity: subQty, menuListId: this.activeListID };
        } else {
            delete this.cart[itemID];
        }
        const qtyElement = event.currentTarget.nextElementSibling;
        qtyElement.innerHTML = `${subQty}`;
        if (subQty > 0) {
            qtyElement.style.display = "block";
        } else {
            qtyElement.style.display = "none";
        }
        this.updateViewOrderBtn();
        console.log("new cart: ", this.cart);
    }

    updateViewOrderBtn() {
        if (Object.keys(this.cart).length > 0) {
            this.viewOrderBtnTarget.classList.add("enabled");
        } else {
            this.viewOrderBtnTarget.classList.remove("enabled");
        }
    }

    submitCart() {
        if (Object.keys(this.cart).length == 0) {
            console.log("empty cart!");
            return;
        }

        const payload = JSON.stringify(this.cart);
        window.sessionStorage.cart = payload;
        this.hiddenFieldTarget.value = payload;
        this.hiddenFormTarget.submit();
    }

    clearSessionStorage() {
      sessionStorage.clear();
    }
}
