// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers";
import 'bootstrap';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// to initialize bootstrap tooltip
import Popper from 'popper.js';
window.Popper = Popper;
import { Tooltip } from 'bootstrap';

document.addEventListener("turbolinks:load", function() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
});


// // testing with bootstrap modal
// document.querySelectorAll(".btn-edit-cluster").forEach(btn => {
//   btn.addEventListener("click", function(event) {
//     // Retrieve the data for the cluster from the button's data attribute and convert to json?
//     let cluster = JSON.parse(event.target.getAttribute('data-cluster'));
//     console.log(cluster)

//     // Get a reference to the modal element
//     let modal = document.getElementById("exampleModal");

//     // populate the form fields with the values of the @cluster
//     document.querySelector("#cluster_id").value = cluster.id; // why must be cluster_id format?
//     document.querySelector(".cluster_form_name").value = cluster.name;
//     document.querySelector(".cluster_form_name_short_form").value = cluster.name_short_form;
//     document.querySelector(".cluster_form_point_postal").value = cluster.point_postal;
//     document.querySelector(".cluster_form_delivery_radius").value = cluster.delivery_radius;
//     document.querySelector(".cluster_form_state").value = cluster.state;

//     // Manually trigger the modal's "show" event - dont think need this (proposed by chatgpt)
//     // modal.classList.add("show");
//     // modal.setAttribute("aria-modal", true);
//     // modal.style.display = "block";
//   });
// });

// const form = document.querySelector("form");
// form.addEventListener("submit", event => {
//   event.preventDefault(); // prevent the form from submitting
//   const formData = new FormData(form); // collect the data from the form fields
//   const cluster_id = document.querySelector("#cluster_id").value; // why must be cluster_id format?
//   const cluster_form_name = document.querySelector(".cluster_form_name").value;
//   const cluster_form_name_short_form = document.querySelector(".cluster_form_name_short_form").value;
//   const cluster_form_point_postal = document.querySelector(".cluster_form_point_postal").value;
//   const cluster_form_state = document.querySelector(".cluster_form_state").value;

//   const options = {
//     headers: {
//       accept: 'application/json',
//       // "X-CSRF-Token": csrf_token
//     },
//     method: "PATCH", // set the request method
//     body: formData // attach thes form data to the request body
//   };

//   fetch(`/update_cluster_with_validation?id=${cluster_id}&name=${cluster_form_name}&short_form=${cluster_form_name_short_form}&point_postal=${cluster_form_point_postal}&state=${cluster_form_state}`,options) // send the request to the backend
//     .then(response => response)
//     .then(data => {
//       console.log(data); // handle the response data
//       // const modal = document.querySelector('#exampleModal');
//       // modal.style.display = "none";
//       window.location.assign("/clusters"); // If you want the user to be able to go back to the previous page, use window.location.assign instead.
//       // window.location.replace("/clusters");
//     })
//     .catch(error => {
//       console.error(error); // handle any errors
//     });
// });


// navbar codes

// document.querySelector("#v-pills-home-tab").addEventListener("click", function(event) {
//   // Retrieve the data for the cluster from the button's data attribute and convert to json?
//   let cluster = JSON.parse(event.target.getAttribute('data-cluster'));
//   console.log(cluster)

//   // Get a reference to the modal element
//   let modal = document.getElementById("exampleModal");

//   // populate the form fields with the values of the @cluster
//   document.querySelector("#cluster_id").value = cluster.id; // why must be cluster_id format?
//   document.querySelector(".cluster_form_name").value = cluster.name;
//   document.querySelector(".cluster_form_name_short_form").value = cluster.name_short_form;
//   document.querySelector(".cluster_form_point_postal").value = cluster.point_postal;
//   document.querySelector(".cluster_form_delivery_radius").value = cluster.delivery_radius;
//   document.querySelector(".cluster_form_state").value = cluster.state;

//   // Manually trigger the modal's "show" event - dont think need this (proposed by chatgpt)
//   // modal.classList.add("show");
//   // modal.setAttribute("aria-modal", true);
//   // modal.style.display = "block";
// });
