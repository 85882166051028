// not in use currently as i disable bootstrap modal

import { Controller } from 'stimulus';
import { map, template, each } from 'underscore';
export default class extends Controller {
    static targets = ["clusterWvEdit", "clusterNvEdit", "clusterWvEditForm", "clusterNvEditForm", "clusterNvCardTitle", "clusterNvListGroupItem", "clusterWvCardTitle", "clusterWvListGroupItem"];

    connect() {
      console.log("Connected!")
    }

    // cluster with no validation
    clusterNvEditClick(event) {
      // to make submit button enabled again
      document.querySelector(".clusterNvEditFormBtn").disabled = false
      let cluster_id = event.target.getAttribute('data-cluster-id');

      fetch(`/edit_cluster_no_validation?id=${cluster_id}`) // send the request to the backend so that we get the latest @cluster
        .then(response => response.json()) // parse the response data as JSON
        .then(data => {
          console.log(data); // data is @cluster in json
          document.querySelector(".clusterNvEditForm1").value = data.id;
          document.querySelector(".clusterNvEditForm2").value = data.name;
          document.querySelector(".clusterNvEditForm3").value = data.name_short_form;
          document.querySelector(".clusterNvEditForm4").value = data.state
        })
        .catch(error => {
          console.error(error); // handle any errors
        });
    }

    // cluster with no validation
    clusterNvEditFormSubmit(event) {
      event.preventDefault(); // prevent the form from submitting
      const formData = new FormData(this.clusterNvEditFormTarget); // collect the data from the form fields

      const clusterNvEditForm1 = document.querySelector(".clusterNvEditForm1").value
      const clusterNvEditForm2 = document.querySelector(".clusterNvEditForm2").value
      const clusterNvEditForm3 = document.querySelector(".clusterNvEditForm3").value
      const clusterNvEditForm4 = document.querySelector(".clusterNvEditForm4").value


      const options = {
        headers: {
          accept: 'application/json',
          // "X-CSRF-Token": csrf_token
        },
        method: "PATCH", // set the request method
        body: formData // attach thes form data to the request body
      };

      fetch(`/update_cluster_no_validation?id=${clusterNvEditForm1}&name=${clusterNvEditForm2}&short_form=${clusterNvEditForm3}&state=${clusterNvEditForm4}`,options) // send the request to the backend
        .then(response => response)
        .then(data => {
          console.log(data); // handle the response data
          // window.location.assign("/clusters"); // using javascript to go to page// If you want the user to be able to go back to the previous page, use window.location.assign instead.
          // window.location.replace("/clusters"); // also using javascript to go to page
          this.clusterNvCardTitleTarget.innerText = clusterNvEditForm3
          this.clusterNvListGroupItemTarget.innerText = clusterNvEditForm4
        })
        .catch(error => {
          console.error(error); // handle any errors
        });
    }

    // cluster with validation
    clusterWvEditClick(event) {
      // to make submit button enabled again
      document.querySelector(".clusterWvEditFormBtn").disabled = false
      let cluster_id = event.target.getAttribute('data-cluster-id');

      fetch(`/edit_cluster_with_validation?id=${cluster_id}`) // send the request to the backend so that we get the latest @cluster
        .then(response => response.json()) // parse the response data as JSON
        .then(data => {
          console.log(data); // data is @cluster in json
          document.querySelector(".clusterWvEditForm1").value = data.id;
          document.querySelector(".clusterWvEditForm2").value = data.name;
          document.querySelector(".clusterWvEditForm3").value = data.name_short_form;
          document.querySelector(".clusterWvEditForm4").value = data.point_postal;
          document.querySelector(".clusterWvEditForm5").value = data.delivery_radius;
          document.querySelector(".clusterWvEditForm6").value = data.state
        })
        .catch(error => {
          console.error(error); // handle any errors
        });
    }

    // cluster with validation
    clusterWvEditFormSubmit(event) {
      event.preventDefault(); // prevent the form from submitting
      const formData = new FormData(this.clusterWvEditFormTarget); // collect the data from the form fields

      const clusterWvEditForm1 = document.querySelector(".clusterWvEditForm1").value
      const clusterWvEditForm2 = document.querySelector(".clusterWvEditForm2").value
      const clusterWvEditForm3 = document.querySelector(".clusterWvEditForm3").value
      const clusterWvEditForm4 = document.querySelector(".clusterWvEditForm4").value
      const clusterWvEditForm5 = document.querySelector(".clusterWvEditForm5").value
      const clusterWvEditForm6 = document.querySelector(".clusterWvEditForm6").value

      const options = {
        headers: {
          accept: 'application/json',
          // "X-CSRF-Token": csrf_token
        },
        method: "PATCH", // set the request method
        body: formData // attach thes form data to the request body
      };

      fetch(`/update_cluster_with_validation?id=${clusterWvEditForm1}&name=${clusterWvEditForm2}&short_form=${clusterWvEditForm3}&point_postal=${clusterWvEditForm4}&delivery_radius=${clusterWvEditForm5}&state=${clusterWvEditForm6}`,options) // send the request to the backend
        .then(response => response)
        .then(data => {
          console.log(data); // handle the response data
          // window.location.assign("/clusters"); // using javascript to go to page// If you want the user to be able to go back to the previous page, use window.location.assign instead.
          // window.location.replace("/clusters"); // also using javascript to go to page
          this.clusterWvCardTitleTarget.innerText = clusterWvEditForm3
          this.clusterWvListGroupItemTarget.innerText = clusterWvEditForm6
        })
        .catch(error => {
          console.error(error); // handle any errors
        });
    }
}



