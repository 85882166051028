import { Controller } from 'stimulus';
import { template, contains, each, reject } from 'underscore';
export default class extends Controller {
    static targets = ["optionsTpl", "modal", "subTotal"];

    connect() {
        this.quantity = 0;
        if (this.element.dataset.initialQty) {
            this.quantity = this.element.dataset.initialQty;
        }
        this.itemID = this.element.dataset.menuItemId;

        this.tpl = template(`{% if (quantity > 0) { %}
            <button class="menu-btn menu-btn-minus" data-action="click->quantity#reduce">
                <span class="icon-font icon-minus"></span>
            </button>
            <span class="menu-item-qty">{{ quantity }}</span>
            <button class="menu-btn menu-btn-plus" data-action="click->quantity#add">
                <span class="icon-font icon-add"></span>
            </button>
        {% } else { %}
            <button class="menu-btn menu-btn-direct-add" data-action="click->quantity#add">Add</button>
        {% } %}`, {
            interpolate: /\{\{(.+?)\}\}/g,
            evaluate: /\{%(.+?)%\}/g,
        })
        this.show()
    }

    reset(event) {
        this.itemID = event.detail.itemID;
        this.quantity = event.detail.quantity;
        this.show()
    }

    show() {
        this.element.innerHTML = this.tpl({ quantity: this.quantity });
    }

    add(event) {
        // console.log(typeof this.quantity)
        this.quantity = parseInt(this.quantity)
        this.quantity += 1;
        this.show();
        this.dispatch("quantityChanged", { detail: {itemID: this.itemID, quantity: this.quantity} });
    }

    reduce(event) {
        // console.log(typeof this.quantity)
        if (this.quantity > 0) {
            this.quantity -= 1;
        }
        this.show();
        this.dispatch("quantityChanged", { detail: {itemID: this.itemID, quantity: this.quantity} });
    }
}
