import { Controller } from 'stimulus';
import { template, contains, each, reject, map, findIndex } from 'underscore';
export default class extends Controller {
    static targets = ["optionsTpl", "modal", "subTotal", "qtyAdjuster", "selectedPanel", "selectedInfo", "productName"];
    // static values = { product: String }

    connect() {
        this.compiledOptionsTpl = template(this.optionsTplTarget.innerHTML, {
            interpolate: /\{\{(.+?)\}\}/g,
            evaluate: /\{%(.+?)%\}/g,
        });
        this.optionsContainer = this.optionsTplTarget.parentElement;
        this.resetModalStatus();
    }

    resetModalStatus() {
        this.activeItem = null;    // represents current item on which user is selecting options
        this.activeSelected = [];  // records selected combos
        this.activeOptions = [];   // represents current display and selected options combination
        this.activeSource = null;  // represents the source DOM element which triggered this modal
    }

    displayOptions(event) {
        this.activeItem = event.detail.item;
        this.activeSource = event.detail.source;

        let nonOptional = []
        this.activeItem.options.forEach((addonGrouping) => {
          if (addonGrouping.optional == false) {
            nonOptional.push(addonGrouping)
          }
        })

        if (event.detail.cartItem && event.detail.cartItem.options) {
            this.activeSelected = event.detail.cartItem.options;
        }
        this.optionsContainer.innerHTML = this.compiledOptionsTpl(this.activeItem);

        this.modalTarget.style.display = "block";
        let banner = document.querySelector("#modal-banner");
        banner.style.height = banner.offsetWidth * (9 / 16) + 'px';
        document.querySelector('body').style.overflowY = 'hidden';

        // run below after the modal is loaded or else document selector dont work
        nonOptional.forEach((addonGrouping) => {
          // add addonGrouping into activeOptions
          this.activeOptions.push({
            name: addonGrouping.values[0].name,
            price: addonGrouping.values[0].price,
            valueSeq: addonGrouping.values[0].valueSeq,
            groupSeq: addonGrouping.values[0].groupSeq,
            value: addonGrouping.values[0].value,
          })
          // hightlight the activeOptions
          document.querySelector(`.options-item[data-value="${addonGrouping.values[0].value}"]`).classList.add("active")
        })

        // this.initializeSelected(); // removed
        // this.refreshSubTotal(); // added
        this.resetQtyAdjuster(); // added
        this.showSelected();
    }

    // remove below to solve the issue where the selected item displayed are wrong
    // initializeSelected() {
    //     let defaultOptions = [];
    //     if (this.activeSelected.length > 0) {
    //         defaultOptions = [...this.activeSelected[this.activeSelected.length - 1].values];
    //     } else {
    //         each(this.activeItem.options, option => {
    //             if (!option.optional && option.values && option.values.length > 0 && option.values[0].value) {
    //                 defaultOptions.push(option.values[0]);
    //             }
    //         })
    //     }
    //     each(defaultOptions, option => {
    //         document.querySelector(`.options-item[data-value=${option.value}]`).classList.add("active");
    //     })
    //     this.activeOptions = defaultOptions;
    //     this.refreshSubTotal();
    //     this.resetQtyAdjuster();
    // }

    // superseded by new code in refreshSelectedLineItem()
    // refreshSubTotal() {
    //     let subTotal = this.activeItem.price;
    //     each(this.activeOptions, option => {
    //         if (option.price && option.price > 0) {
    //             subTotal += option.price;
    //         }
    //     })
    //     this.subTotalTarget.innerHTML = `$${subTotal.toFixed(2)}`;
    // }

    closeModal(event) {
        this.modalTarget.style.display = "none";
        document.querySelector('body').style.overflowY = 'scroll';

        this.dispatch("optionsSelected", {
            detail: {selected: this.activeSelected},
            target: this.activeSource,
        });
        this.resetModalStatus();
    }

    // original codes
    // showSelected() {
    //     if (this.activeSelected.length > 0) {
    //         const info = map(this.activeSelected, selected => {
    //             return map(selected.values, option => {
    //                 return option.name
    //             }).join(", ") + ` x ${selected.quantity}`
    //         }).join("<br/>");

    //         this.selectedInfoTarget.innerHTML = info;
    //         this.selectedPanelTarget.style.display = "block";
    //     } else {
    //         this.selectedPanelTarget.style.display = "none";
    //     }
    // }

    showSelected() {
        if (this.activeSelected.length > 0) {
            this.refreshSelectedLineItem()
            this.persistedHighlight()

            this.selectedPanelTarget.style.display = "block";
        } else {
            this.refreshSelectedLineItem()
            this.selectedPanelTarget.style.display = "none";
        }
    }

    // click event
    highlightOption(event){
      // console.log("hit highlightOption")
      this.refreshSelectedLineItem()
      this.persistedHighlight()
      // this.refreshSubTotal();
      this.resetQtyAdjuster();
    }

    // activeOptions are currently selected
    // activeSelected are all items added into cart

    // click event
    highlightSelected(event){
      // get the activeOptions data based on the index of the activeSelected
      let activeSelectedArr = this.activeSelected[event.target.dataset.optionId]

      // wipe activeOptions and remove highlight for all option first
      this.activeOptions = []
      document.querySelectorAll(".options-item").forEach((optionItem) => {
        optionItem.classList.remove("active")
      })
      document.querySelectorAll(".selected-option").forEach((selectedLineItem) => {
        selectedLineItem.classList.remove("active")
      })

      //highlight selected line item
      event.target.classList.add("active")

      // populate activeOptions and highlight the selected options
      activeSelectedArr.values.forEach((addonGrouping) => {
        // add addonGrouping into activeOptions
        this.activeOptions.push({
          name: addonGrouping.name,
          price: addonGrouping.price,
          valueSeq: addonGrouping.valueSeq,
          groupSeq: addonGrouping.groupSeq,
          value: addonGrouping.value,
        })
        // highlight selected options
        document.querySelector(`.options-item[data-value="${addonGrouping.value}"]`).classList.add("active")
      })

      // this.refreshSubTotal();
      this.resetQtyAdjuster();
    }

    // check what is activeOptions then highlight the selected line items
    persistedHighlight() {
      console.log("hit persistedhighlight()")
      // wipe activeOptions and remove highlight for all option first
      document.querySelectorAll(".options-item").forEach((optionItem) => {
        optionItem.classList.remove("active")
      })
      document.querySelectorAll(".selected-option").forEach((selectedLineItem) => {
        selectedLineItem.classList.remove("active")
      })

      // select options
      let activeOptionsIds = this.activeOptions.map((addon) => {
        // highlight selected options
        document.querySelector(`.options-item[data-value="${addon.value}"]`).classList.add("active")
        return addon.value
      }).sort()

      // select line items
      let activeSelectedIds = this.activeSelected.map((addonGrouping) => {
        return addonGrouping.values.map((addon) => {
          return addon.value
        }).sort()
      })


      let activeSelectedIdx = -1
      activeSelectedIds.forEach((id_arr) => {
        activeSelectedIdx++
        if (JSON.stringify(id_arr) === JSON.stringify(activeOptionsIds)) {
          console.log("Match", JSON.stringify(id_arr), JSON.stringify(activeSelectedIds))
          // highlight line items
          document.querySelector(`.selected-option[data-option-id="${activeSelectedIdx}"]`).classList.add("active")
        } else {
          console.log("Dont match", JSON.stringify(id_arr), JSON.stringify(activeSelectedIds))
        }
      })
    }

    toggleOptionStatus(event) {
        const target = event.currentTarget;
        const activeOptions = this.getAllSiblings(target, (sibling) => {
            return contains(sibling.classList, "active");
        })

        if (contains(target.classList, "active")) { // toggle off current option
            if (activeOptions.length > 1 || target.parentElement.dataset.optional == "true") {
                target.classList.remove("active");
                this.activeOptions = reject(this.activeOptions, option => {
                    return option.value == target.dataset.value;
                })
            }
        } else { // toggle on current option
            if (target.parentElement.dataset.single == "true") {
                each(activeOptions, (e) => {
                    // toggle on a option within a single choice group will automatically toggle off other options
                    e.classList.remove("active");
                    this.activeOptions = reject(this.activeOptions, option => {
                        return option.value == e.dataset.value;
                    })
                })
            }
            target.classList.add("active");
            // search the corresponding value data
            each(this.activeItem.options, option => {
                each(option.values, v => {
                    if (v.value == target.dataset.value) {
                        this.activeOptions.push(v);
                        this.activeOptions.sort((a, b) => {
                            if (a.group_seq == b.group_seq) {
                                return a.value_seq - b.value_seq;
                            }
                            return a.group_seq - b.group_seq;
                        });
                    }
                })
            })
        }
        console.log("activeOptions", this.activeOptions)

        this.resetQtyAdjuster();
        // this.refreshSubTotal();
    }

    resetQtyAdjuster() {
        let quantity = 0;
        const idx = findIndex(this.activeSelected, selected=> {
            return this.getOptionsKey(selected.values) == this.getOptionsKey(this.activeOptions);
        });
        if (idx >= 0) {
            const selected = this.activeSelected[idx];
            quantity = selected.quantity;
        }
        this.dispatch("initialized", {
            detail: {itemID: this.activeItem.id, quantity: quantity},
            target: this.qtyAdjusterTarget,
        });
    }

    refreshSelectedLineItem() {
      console.log("activeItem", this.activeItem)
      console.log("activeSelected", this.activeSelected)
      console.log("activeOptions", this.activeOptions)
      console.log("activeSource", this.activeSource)

      let activeSelectedIdx = -1
      let total = 0

      const info = map(this.activeSelected, selected => {
        let subTotal = this.activeItem.price;

        let combinedText = map(selected.values, option => {
          subTotal += option.price;
          return option.name
        }).join(", ");

        subTotal *= selected.quantity;
        total += subTotal
        subTotal = `$${subTotal.toFixed(2)}`;
        activeSelectedIdx++

        if (combinedText.length == 0){
          return `<div class="selected-option" data-option-id="${activeSelectedIdx}" data-action="click->modal#highlightSelected"> ${this.activeItem.name} ${combinedText} x ${selected.quantity} [${subTotal}]</div>`
        }
        else {
          return `<div class="selected-option" data-option-id="${activeSelectedIdx}" data-action="click->modal#highlightSelected"> ${this.activeItem.name} ( ${combinedText} ) x ${selected.quantity} [${subTotal}]</div>`
        }
      });

      this.selectedInfoTarget.innerHTML = info.join("");
      this.subTotalTarget.innerHTML = `$${total.toFixed(2)}`;
    }

    getAllSiblings(elem, filter) {
        var sibs = [];
        elem = elem.parentNode.firstChild;
        do {
            if (elem.nodeType === 3) continue; // text node
            if (!filter || filter(elem)) sibs.push(elem);
        } while (elem = elem.nextSibling)
        return sibs;
    }

    getOptionsKey(values) {
        return map(values, value=>{
            return value.value;
        }).sort().join(",");
    }

    quantityChanged(event) {
        const idx = findIndex(this.activeSelected, selected=> {
            return this.getOptionsKey(selected.values) == this.getOptionsKey(this.activeOptions);
        });
        if (event.detail.quantity > 0) { // there is still non-zero quantity, simply overwrite quantity
            if(idx >= 0) { // the combo is existed already, just overwrite its quantity
                this.activeSelected[idx].quantity = event.detail.quantity;
            } else { // the combo is a new one, append it to the selected combos array
                this.activeSelected.push({values: [...this.activeOptions], quantity: event.detail.quantity});
            }
        } else { // this combo is cleared, need to pop the selected combo
            if (idx >= 0) {
                this.activeSelected.splice(idx, 1);
            }
        }

        console.log("activeSelected: ", this.activeSelected);
        this.showSelected();
    }
}
