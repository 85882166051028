import { Controller } from 'stimulus';
import { map, template, each } from 'underscore';
export default class extends Controller {
    static targets = ["pills"];

    connect() {
      console.log("Connected!")

      // document.addEventListener("turbolinks:before-visit", () => {
      //   this.showLoadingScreen();
      // });

      // handles turbo link load
      document.addEventListener("turbolinks:load", () => {
      // document.addEventListener("turbolinks:before-visit", () => {
        this.hideLoadingScreen();
      });

      // handles normal window load
      window.addEventListener("load", () => {
        this.hideLoadingScreen();
      });

      this.setActivePill("desktop") // by default highlight the pills in desktop format
    }

    navigate(event) {
      // event.preventDefault();

      // Show the loading screen
      this.showLoadingScreen();

      let target = event.target.dataset.bsTarget;

      // localStorage.setItem("activePill", target) // store data in local storage

      if (target === "#v-pills-home") {
        window.location.href = "/clusters";
      } else if (target === "#v-pills-profile") {
        window.location.assign("/users")
      } else if (target === "#v-pills-consumers") {
        window.location.assign("/consumers")
      } else if (target === "#v-pills-vendors") {
        window.location.assign("/vendors")
      } else if (target === "#v-pills-products") {
        window.location.assign("/products")
      } else if (target === "#v-pills-addons") {
        window.location.assign("/addons")
      } else if (target === "#v-pills-analytics") {
        window.location.assign("/consumer_metrics")
      } else if (target === "#v-pills-chatgroups") {
        window.location.assign("/chat_groups")
      } else if (target === "#v-pills-messagetemplates") {
        window.location.assign("/message_templates")
      } else if (target === "#v-pills-offeringtemplates") {
        window.location.assign("/offering_templates")
      } else if (target === "#v-pills-offeringadmins") {
        window.location.assign("/offering_admins")
      } else if (target === "#v-pills-consolidatedorders") {
        window.location.assign("/orders_by_dates")
      }
      // add similar logic for the other pills

      // trying to circumvent anchor issue
      // if (target === "#v-pills-home") {
      //   window.location.href = "/clusters";
      // } else if (target === "#v-pills-profile") {
      //   window.location.href = "/users";
      // } else if (target === "#v-pills-consumers") {
      //   window.location.href = "/consumers";
      // } else if (target === "#v-pills-vendors") {
      //   window.location.href = "/vendors";
      // } else if (target === "#v-pills-products") {
      //   window.location.href = "/products";
      // } else if (target === "#v-pills-addons") {
      //   window.location.href = "/addons";
      // } else if (target === "#v-pills-analytics") {
      //   window.location.href = "/consumer_metrics";
      // } else if (target === "#v-pills-chatgroups") {
      //   window.location.href = "/chat_groups";
      // } else if (target === "#v-pills-messagetemplates") {
      //   window.location.href = "/message_templates";
      // } else if (target === "#v-pills-offeringtemplates") {
      //   window.location.href = "/offering_templates";
      // } else if (target === "#v-pills-offeringadmins") {
      //   window.location.href = "/offering_admins";
      // } else if (target === "#v-pills-consolidatedorders") {
      //   window.location.href = "/orders_by_dates";
      // }
    }

    highlightInOffCanvas() {
      console.log("highlightInOffCanvas")
      // Hide the loading screen once the new page is loaded and ready to be displayed.
      this.setActivePill("mobile")
    }

    setActivePill(navBarType) {
      let currentUrl = window.location.href
      console.log(currentUrl)
      if (currentUrl.includes("/clusters")) {
        this.setPillActive("#v-pills-home", navBarType)
      } else if (currentUrl.includes("/users")) {
        this.setPillActive("#v-pills-profile", navBarType)
      } else if (currentUrl.includes("/consumers")) {
        this.setPillActive("#v-pills-consumers", navBarType)
      } else if (currentUrl.includes("/vendors")) {
        this.setPillActive("#v-pills-vendors", navBarType)
      } else if (currentUrl.includes("/products")) {
        this.setPillActive("#v-pills-products", navBarType)
      } else if (currentUrl.includes("/addons")) {
        this.setPillActive("#v-pills-addons", navBarType)
      } else if (currentUrl.includes("/consumer_metrics")) {
        this.setPillActive("#v-pills-analytics", navBarType)
      } else if (currentUrl.includes("/chat_groups")) {
        this.setPillActive("#v-pills-chatgroups", navBarType)
      } else if (currentUrl.includes("/message_templates")) {
        this.setPillActive("#v-pills-messagetemplates", navBarType)
      } else if (currentUrl.includes("/offering_templates")) {
        this.setPillActive("#v-pills-offeringtemplates", navBarType)
      } else if (currentUrl.includes("/offering_admins")) {
        this.setPillActive("#v-pills-offeringadmins", navBarType)
      } else if (currentUrl.includes("/orders_by_dates")) {
        this.setPillActive("#v-pills-consolidatedorders", navBarType)
      }
      // add similar logic for the other pills
    }

    setPillActive(pillId, navBarType) {
      this.pillsTargets.forEach((pill) => {
        pill.classList.remove("active")
        // pill.classList.remove("show")
      })
      if (navBarType == "desktop") {
        let pills = document.querySelector(pillId + "-desktop-tab")
        pills.classList.add("active")
      } else if (navBarType == "mobile") {
        let pills = document.querySelector(pillId + "-mobile-tab")
        pills.classList.add("active")
      }
      // pill.classList.remove("show")
    }

    showLoadingScreen() {
      // Show the loading screen, for example using an overlay or a spinner.
      document.querySelector(".loading-overlay").style.display = "flex";
    }

    hideLoadingScreen() {
      // Hide the loading screen once the new page is loaded and ready to be displayed.
      document.querySelector(".loading-overlay").style.display = "none";
    }
}
